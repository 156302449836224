import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import fpComic from '../images/fp-comic.png';
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`The Road to Functional Programming`}</h1>
    <p>{`Let's start by defining Functional Programming (FP). Wikipedia's definition:`}</p>
    <blockquote>
      <p parentName="blockquote">{`In computer science, functional programming is a programming paradigm—a style of building the structure and elements of computer programs—that treats computation as the evaluation of mathematical functions and avoids changing-state and mutable data. It is a declarative programming paradigm in that programming is done with expressions or declarations instead of statements.`}</p>
    </blockquote>
    <p>{`My interest in FP didn't come out of nowhere. This progression implicitly started through my interest in mathematics. In college, I earned a minor in Mathematics alongside a bachelor degree in Computer Science. However, the CS program strongly showcased procedural programming as well as object oriented programming (OOP) concepts. `}<strong parentName="p">{`Coming out of college I was not aware of the FP paradigm.`}</strong>{` However, I `}<em parentName="p">{`was`}</em>{` aware that I enjoyed mathematical theory.`}</p>
    <p>{`Early in my career I was introduced to declarative programming by a mentor of mine teaching me about Language Integrated Queries (LINQ) in C# (shout out to John Avery). I was able to compare my previously procedural code which, at best, explained `}<em parentName="p">{`how`}</em>{` the program was working with this new declarative implementation which clearly described `}<em parentName="p">{`what`}</em>{` the program was doing. This was the first "aha!" moment for me.`}</p>
    <p>{`The next light bulb moment happened when I realized that just by leveraging React in my projects I was unknowingly practicing some FP concepts via higher-order components, property immutability and component composition. This is when I decided to invest in learning functional programming.`}</p>
    <h2><HashLink id="journey-begins" to="/road-to-fp#journey-begins" mdxType="HashLink">{`The journey begins`}</HashLink></h2>
    <p>{`The following professional project I worked on was a greenfield, large-scale web application for a country-wide insurance company. My coworkers and I made early decisions to leverage FP as much as possible. The first of which was to install `}<a parentName="p" {...{
        "href": "https://ramdajs.com/"
      }}>{`Ramda`}</a>{` instead of Lodash for working with our client-side data. Meanwhile, I was picking up beginner FP concepts through learning material such as `}<a parentName="p" {...{
        "href": "http://randycoulman.com/blog/2016/05/24/thinking-in-ramda-getting-started/"
      }}>{`Thinking in Ramda`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/getify/Functional-Light-JS"
      }}>{`Functional-Light JavaScript`}</a>{`. These were great resources for grasping foundational concepts without having to tackle category theory or having to understand what a monad is.`}</p>
    <p>{`It was soon thereafter that my coworkers and I realized we could only get so far in our practical experience using Ramda. We were using TypeScript on our project and there were some awesome Ramda functions that happened to completely abandon type safety. Now, we could have contributed the the open source types for the Ramda library or created our own types, however, we had client work to deliver and many of us were still in the early-stages of our FP learning. Therefore we resolved to avoid some fancy Ramda functions in favor of vanilla JavaScript.`}</p>
    <p>{`The next step for me was to enjoy the `}<a parentName="p" {...{
        "href": "https://soundcloud.com/lambda-cast"
      }}>{`LambdaCast podcast`}</a>{` where the hosts strive to "describe concepts using clear everyday language without watering anything down". The subject matter of this podcast includes quite the deep dive into some great FP ingredients. That being said, the hosts do a great job of slowly increasing the complexity of the material while recapping relevant past episodes. What's more, one of the hosts starts with little-to-no FP experience (coming from a .NET development background) and is learning with the listener throughout the cast. This allows for an inexperienced FP developer to ask the experts good questions throughout the cast.`}</p>
    <p>{`It wasn't long before some coworkers mentioned the Elm programming language. Elm is a statically typed, purely functional language for declaratively creating web apps. Curiously, I started researching Elm by reading through the docs which ultimately led me to read the `}<em parentName="p">{`Programming Elm`}</em>{` book by Jeremy Fairbank.`}</p>
    <p>{`There are many takeaways from all of this FP investigation but there are a few aspects to highlight, in particular.`}</p>
    <h2><HashLink id="pure-functions" to="/road-to-fp#pure-functions" mdxType="HashLink">{`Pure functions`}</HashLink></h2>
    <p>{`The building blocks of the functional world. You can run a pure, side-effect-free function as many times as you'd like with the same input and `}<strong parentName="p">{`always`}</strong>{` get the same output. These bad boys give the programmer peace of mind especially when combined with a robust type system and some parametric polymorphism`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`. Pure functions are easily testable and can reduce cognitive overhead when building an application.`}</p>
    <h2><HashLink id="composition" to="/road-to-fp#composition" mdxType="HashLink">{`Function composition`}</HashLink></h2>
    <p>{`Another foundational aspect of FP is being able to combine functions together by passing the return value of one function as an argument to the next. This is very powerful on its own but becomes even more powerful when you incorporate partial application`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` and currying`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup>{`.`}</p>
    <h2><HashLink id="immutability" to="/road-to-fp#immutability" mdxType="HashLink">{`Immutability`}</HashLink></h2>
    <p>{`If your data structures are immutable you don't have to worry about performing deep equality comparisons. Either the structure is the same or it's not. Nice and simple. Another bonus is that you will reduce the risk of accidental side effects`}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup>{`. How can you truly be certain that the logic you're writing around some data won't produce undesired results if that data can change elsewhere in the source code?`}</p>
    <h2><HashLink id="learning-curve" to="/road-to-fp#learning-curve" mdxType="HashLink">{`Learning curve`}</HashLink></h2>
    <p>{`Make no mistake, coming from an imperative, OOP background and learning FP is no trivial endeavor. The barrier to entry is pretty high but the rewards seem worth it to me. Unfortunately, FP isn't the hottest buzzword in enterprise organizations. It would take some buy-in from stakeholders and team members to commit to leveraging a functional-first language such as Elm, Haskell, etc. Whomever works on the project would need to invest in the paradigm and that's just not worth it to some folks.`}</p>
    <div style={{
      display: 'flex',
      justifyContent: 'center'
    }}>
    <img alt="Functional programming comic" title="https://xkcd.com/1270/" src={fpComic} width={300} />
    </div>
    <p>{`Hopefully FP can become more of a "main stream" paradigm in the community. While I can't help but feel like I've only discovered the tip of the iceberg here, I'm excited to learn how to scubadive in freezing cold waters.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`"In programming languages and type theory, `}<strong parentName="li">{`parametric polymorphism`}</strong>{` is a way to make a language more expressive, while still maintaining full static type-safety. Using parametric polymorphism, a function or a data type can be written generically so that it can handle values identically without depending on their type." - `}<em parentName="li">{`Wikipedia`}</em><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`"In computer science, `}<strong parentName="li">{`partial application`}</strong>{` (or partial function application) refers to the process of fixing a number of arguments to a function, producing another function of smaller arity." - `}<em parentName="li">{`Wikipedia`}</em><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`"In mathematics and computer science, `}<strong parentName="li">{`currying`}</strong>{` is the technique of translating the evaluation of a function that takes multiple arguments into evaluating a sequence of functions, each with a single argument." - `}<em parentName="li">{`Wikipedia`}</em><a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`"In computer science, an operation, function or expression is said to have a `}<strong parentName="li">{`side effect`}</strong>{` if it modifies some state variable value(s) outside its local environment" - `}<em parentName="li">{`Wikipedia`}</em><a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      